<template>
  <div class="shop-products-combined">
    <div
      class="shop-products-combined__filter"
      :class="{
        'lift-index': selectFieldOpen,
      }"
    >
      <FilterText
        v-if="data.shop_sections[0].text_filter.length === 1"
        :data="data.shop_sections[0].text_filter[0]"
        :is-hidden="data.shop_sections[0].hide_text_filter"
        :is-general="false"
        @set-text-search="setFilterTextSearch"
      />

      <div class="shop-products-combined__filter-advanced-teleport" />
      <div class="shop-products-combined__sticky-background" />
    </div>

    <div
      v-for="(section, index) of data.shop_sections"
      :key="section._uid"
    >
      <ShopProductsNew
        v-if="section.component === 'ShopProductsNew'"
        :data="section"
        :global-filter-data="rawAdvancedFilterQuery"
        :use-global-advanced-filter="true"
      />

      <ShopProductsMix
        v-else-if="section.component === 'ShopProductsMix'"
        :data="section"
        :speaker="speaker"
        :render-advanced-filter="index === 0"
        :is-combined="true"
        :passed-in-speaker="speaker"
        :global-filter-data="rawAdvancedFilterQuery"
        :use-global-advanced-filter="true"
        @set-filter-advanced="setFilterAdvanced"
        @set-availability="setAvailability"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
});

const rawAdvancedFilterQuery = reactive({
    availability: 'all',
    relationalByText: {
        searchString: '',
        additional_relations: {},
    },
    relationalByName: null,
});

const setFilterTextSearch = (data) => {
    const values = typeof data.values === 'string' ? data.values : '';
    rawAdvancedFilterQuery.relationalByText.searchString = values;
};

const setFilterAdvanced = (data) => {
    rawAdvancedFilterQuery.relationalByName = data?.values || {};
};

const setAvailability = (data) => {
    rawAdvancedFilterQuery.availability = data.values;
};

if (props.data.shop_sections?.length > 0) {
    const sections = [...props.data.shop_sections];

    if (sections[0].text_filter?.length === 1) {
        const flattened = sections[0].text_filter[0].additional_relational_search?.map(
            (item) => item,
        );

        rawAdvancedFilterQuery.relationalByText.additional_relations = flattened;
    }
}

const selectFieldPopupStore = useSelectFieldPopupStore();
const selectFieldOpen = computed(() => selectFieldPopupStore.isOpen);
const speaker = ref(null);

const {
    getRecommendedSpeakerById,
    getRecommendedSpeakerByUuid,
} = useRecommendedSpeaker();
const storyStore = useStoryStore();
const predefinedSpeakerUUID = storyStore.getCurrentStory?.story?.content?.recommended_speaker;
if (predefinedSpeakerUUID) {
    speaker.value = getRecommendedSpeakerByUuid(predefinedSpeakerUUID);
} else {
    const context = useContextStore();
    const speakerIds = context.getSpeakerId?.length > 0 ? context.getSpeakerId : 2;
    speaker.value = getRecommendedSpeakerById(speakerIds);
}
</script>

<style lang="scss" scoped>
.shop-products-combined {
    @include grid-columns();
    @include fluid('margin-bottom', 60px, 120px);
}

.shop-products-combined__filter {
    @include grid-columns();
    // @include fluid('margin-bottom', 50px, 90px);
    @include fluid('padding-top', 20px, 40px);
    @include fluid('padding-bottom', 10px, 30px);

    position: sticky;
    z-index: 20;
    top: var(--headerHeight);
    display: block;
    background: $C_WHITE;

    &.lift-index {
        z-index: 150;
    }
}

.shop-products-combined__sticky-background {
    @include grid-full;
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
    background: $C_WHITE;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
    pointer-events: none;
}
</style>
